import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601DateTime: { input: string; output: string; }
};

/** Autogenerated input type of CreatePublisherMutation */
export type CreatePublisherMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rootOwnerId: Scalars['ID']['input'];
  shopifyDomain: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePublisherMutation. */
export type CreatePublisherMutationPayload = {
  __typename?: 'CreatePublisherMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  publisher: Publisher;
};

export type Mutation = {
  __typename?: 'Mutation';
  createPublisher?: Maybe<CreatePublisherMutationPayload>;
  /** An example field added by the generator */
  testField: Scalars['String']['output'];
  updatePublisher?: Maybe<UpdatePublisherMutationPayload>;
};


export type MutationCreatePublisherArgs = {
  input: CreatePublisherMutationInput;
};


export type MutationUpdatePublisherArgs = {
  input: UpdatePublisherMutationInput;
};

export type Publisher = {
  __typename?: 'Publisher';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  rootOwnerId: Scalars['ID']['output'];
  shop: Shop;
  shopifyDomain: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  publisher: Publisher;
  publishers: Array<Publisher>;
  rootOwners: Array<RootOwner>;
  /** An example field added by the generator */
  testField: Scalars['String']['output'];
};


export type QueryPublisherArgs = {
  id: Scalars['Int']['input'];
};

export type RootOwner = {
  __typename?: 'RootOwner';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Shop = {
  __typename?: 'Shop';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  shopifyDomain: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of UpdatePublisherMutation */
export type UpdatePublisherMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated return type of UpdatePublisherMutation. */
export type UpdatePublisherMutationPayload = {
  __typename?: 'UpdatePublisherMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  publisher: Publisher;
};

export type CreatePublisherMutationVariables = Exact<{
  name: Scalars['String']['input'];
  rootOwnerId: Scalars['ID']['input'];
  shopifyDomain: Scalars['String']['input'];
}>;


export type CreatePublisherMutation = { __typename?: 'Mutation', createPublisher?: { __typename?: 'CreatePublisherMutationPayload', errors: Array<string>, publisher: { __typename?: 'Publisher', id: number, name: string, rootOwnerId: string, createdAt: string, updatedAt: string, shop: { __typename?: 'Shop', shopifyDomain: string } } } | null };

export type PublisherQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type PublisherQuery = { __typename?: 'Query', publisher: { __typename?: 'Publisher', id: number, name: string, rootOwnerId: string, createdAt: string, updatedAt: string, shop: { __typename?: 'Shop', shopifyDomain: string } } };

export type PublisherListQueryVariables = Exact<{ [key: string]: never; }>;


export type PublisherListQuery = { __typename?: 'Query', publishers: Array<{ __typename?: 'Publisher', id: number, name: string, rootOwnerId: string, shopifyDomain: string, createdAt: string, updatedAt: string }> };

export type RootOwnerListQueryVariables = Exact<{ [key: string]: never; }>;


export type RootOwnerListQuery = { __typename?: 'Query', rootOwners: Array<{ __typename?: 'RootOwner', id: string, name: string, createdAt: string, updatedAt: string }> };

export type TestInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type TestInfoQuery = { __typename?: 'Query', testField: string };

export type UpdatePublisherMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdatePublisherMutation = { __typename?: 'Mutation', updatePublisher?: { __typename?: 'UpdatePublisherMutationPayload', errors: Array<string>, publisher: { __typename?: 'Publisher', id: number, name: string, rootOwnerId: string, createdAt: string, updatedAt: string, shop: { __typename?: 'Shop', shopifyDomain: string } } } | null };


export const CreatePublisherDocument = gql`
    mutation createPublisher($name: String!, $rootOwnerId: ID!, $shopifyDomain: String!) {
  createPublisher(
    input: {name: $name, rootOwnerId: $rootOwnerId, shopifyDomain: $shopifyDomain}
  ) {
    publisher {
      id
      name
      rootOwnerId
      shop {
        shopifyDomain
      }
      createdAt
      updatedAt
    }
    errors
  }
}
    `;
export type CreatePublisherMutationFn = Apollo.MutationFunction<CreatePublisherMutation, CreatePublisherMutationVariables>;

/**
 * __useCreatePublisherMutation__
 *
 * To run a mutation, you first call `useCreatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherMutation, { data, loading, error }] = useCreatePublisherMutation({
 *   variables: {
 *      name: // value for 'name'
 *      rootOwnerId: // value for 'rootOwnerId'
 *      shopifyDomain: // value for 'shopifyDomain'
 *   },
 * });
 */
export function useCreatePublisherMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublisherMutation, CreatePublisherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublisherMutation, CreatePublisherMutationVariables>(CreatePublisherDocument, options);
      }
export type CreatePublisherMutationHookResult = ReturnType<typeof useCreatePublisherMutation>;
export type CreatePublisherMutationResult = Apollo.MutationResult<CreatePublisherMutation>;
export type CreatePublisherMutationOptions = Apollo.BaseMutationOptions<CreatePublisherMutation, CreatePublisherMutationVariables>;
export const PublisherDocument = gql`
    query publisher($id: Int!) {
  publisher(id: $id) {
    id
    name
    rootOwnerId
    shop {
      shopifyDomain
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __usePublisherQuery__
 *
 * To run a query within a React component, call `usePublisherQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublisherQuery(baseOptions: Apollo.QueryHookOptions<PublisherQuery, PublisherQueryVariables> & ({ variables: PublisherQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherQuery, PublisherQueryVariables>(PublisherDocument, options);
      }
export function usePublisherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherQuery, PublisherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherQuery, PublisherQueryVariables>(PublisherDocument, options);
        }
export function usePublisherSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublisherQuery, PublisherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublisherQuery, PublisherQueryVariables>(PublisherDocument, options);
        }
export type PublisherQueryHookResult = ReturnType<typeof usePublisherQuery>;
export type PublisherLazyQueryHookResult = ReturnType<typeof usePublisherLazyQuery>;
export type PublisherSuspenseQueryHookResult = ReturnType<typeof usePublisherSuspenseQuery>;
export type PublisherQueryResult = Apollo.QueryResult<PublisherQuery, PublisherQueryVariables>;
export function refetchPublisherQuery(variables: PublisherQueryVariables) {
      return { query: PublisherDocument, variables: variables }
    }
export const PublisherListDocument = gql`
    query publisherList {
  publishers {
    id
    name
    rootOwnerId
    shopifyDomain
    createdAt
    updatedAt
  }
}
    `;

/**
 * __usePublisherListQuery__
 *
 * To run a query within a React component, call `usePublisherListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublisherListQuery(baseOptions?: Apollo.QueryHookOptions<PublisherListQuery, PublisherListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherListQuery, PublisherListQueryVariables>(PublisherListDocument, options);
      }
export function usePublisherListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherListQuery, PublisherListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherListQuery, PublisherListQueryVariables>(PublisherListDocument, options);
        }
export function usePublisherListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublisherListQuery, PublisherListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublisherListQuery, PublisherListQueryVariables>(PublisherListDocument, options);
        }
export type PublisherListQueryHookResult = ReturnType<typeof usePublisherListQuery>;
export type PublisherListLazyQueryHookResult = ReturnType<typeof usePublisherListLazyQuery>;
export type PublisherListSuspenseQueryHookResult = ReturnType<typeof usePublisherListSuspenseQuery>;
export type PublisherListQueryResult = Apollo.QueryResult<PublisherListQuery, PublisherListQueryVariables>;
export function refetchPublisherListQuery(variables?: PublisherListQueryVariables) {
      return { query: PublisherListDocument, variables: variables }
    }
export const RootOwnerListDocument = gql`
    query rootOwnerList {
  rootOwners {
    id
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useRootOwnerListQuery__
 *
 * To run a query within a React component, call `useRootOwnerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootOwnerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootOwnerListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRootOwnerListQuery(baseOptions?: Apollo.QueryHookOptions<RootOwnerListQuery, RootOwnerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RootOwnerListQuery, RootOwnerListQueryVariables>(RootOwnerListDocument, options);
      }
export function useRootOwnerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RootOwnerListQuery, RootOwnerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RootOwnerListQuery, RootOwnerListQueryVariables>(RootOwnerListDocument, options);
        }
export function useRootOwnerListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RootOwnerListQuery, RootOwnerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RootOwnerListQuery, RootOwnerListQueryVariables>(RootOwnerListDocument, options);
        }
export type RootOwnerListQueryHookResult = ReturnType<typeof useRootOwnerListQuery>;
export type RootOwnerListLazyQueryHookResult = ReturnType<typeof useRootOwnerListLazyQuery>;
export type RootOwnerListSuspenseQueryHookResult = ReturnType<typeof useRootOwnerListSuspenseQuery>;
export type RootOwnerListQueryResult = Apollo.QueryResult<RootOwnerListQuery, RootOwnerListQueryVariables>;
export function refetchRootOwnerListQuery(variables?: RootOwnerListQueryVariables) {
      return { query: RootOwnerListDocument, variables: variables }
    }
export const TestInfoDocument = gql`
    query testInfo {
  testField
}
    `;

/**
 * __useTestInfoQuery__
 *
 * To run a query within a React component, call `useTestInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestInfoQuery(baseOptions?: Apollo.QueryHookOptions<TestInfoQuery, TestInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestInfoQuery, TestInfoQueryVariables>(TestInfoDocument, options);
      }
export function useTestInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestInfoQuery, TestInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestInfoQuery, TestInfoQueryVariables>(TestInfoDocument, options);
        }
export function useTestInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestInfoQuery, TestInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestInfoQuery, TestInfoQueryVariables>(TestInfoDocument, options);
        }
export type TestInfoQueryHookResult = ReturnType<typeof useTestInfoQuery>;
export type TestInfoLazyQueryHookResult = ReturnType<typeof useTestInfoLazyQuery>;
export type TestInfoSuspenseQueryHookResult = ReturnType<typeof useTestInfoSuspenseQuery>;
export type TestInfoQueryResult = Apollo.QueryResult<TestInfoQuery, TestInfoQueryVariables>;
export function refetchTestInfoQuery(variables?: TestInfoQueryVariables) {
      return { query: TestInfoDocument, variables: variables }
    }
export const UpdatePublisherDocument = gql`
    mutation updatePublisher($id: Int!, $name: String!) {
  updatePublisher(input: {id: $id, name: $name}) {
    publisher {
      id
      name
      rootOwnerId
      shop {
        shopifyDomain
      }
      createdAt
      updatedAt
    }
    errors
  }
}
    `;
export type UpdatePublisherMutationFn = Apollo.MutationFunction<UpdatePublisherMutation, UpdatePublisherMutationVariables>;

/**
 * __useUpdatePublisherMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherMutation, { data, loading, error }] = useUpdatePublisherMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdatePublisherMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublisherMutation, UpdatePublisherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublisherMutation, UpdatePublisherMutationVariables>(UpdatePublisherDocument, options);
      }
export type UpdatePublisherMutationHookResult = ReturnType<typeof useUpdatePublisherMutation>;
export type UpdatePublisherMutationResult = Apollo.MutationResult<UpdatePublisherMutation>;
export type UpdatePublisherMutationOptions = Apollo.BaseMutationOptions<UpdatePublisherMutation, UpdatePublisherMutationVariables>;