import { Link } from "react-router-dom";

import { serverState } from "../serverState";

export default function Home() {
  return (
    <>
      <ul>
        {!serverState.isLoggedIn && (
          <li>
            <Link to="/sign_in">ログイン</Link>
          </li>
        )}
        <li>
          <Link to="/publishers">出版社一覧</Link>
        </li>
        <li>
          <Link to="/publishers/new">出版社登録</Link>
        </li>
      </ul>
    </>
  );
}
