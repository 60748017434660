import { AlertCircle } from "lucide-react";

import {
  AlertDescription,
  AlertTitle,
  Alert as AlertUI,
} from "@/components/ui/alert";

export type AlertProps = {
  alertMessage: string;
};

export const Alert = ({ alertMessage }: AlertProps) => {
  return (
    <>
      {alertMessage != "" && (
        <AlertUI variant="destructive" className="my-5">
          <AlertCircle />
          <AlertTitle>エラーが発生しました</AlertTitle>
          <AlertDescription>{alertMessage}</AlertDescription>
        </AlertUI>
      )}
    </>
  );
};
