import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { PublisherForm } from "@/components/PublisherForm";
import { alertContext } from "@/entrypoints/App";
import {
  refetchPublisherListQuery,
  useCreatePublisherMutation,
} from "@/graphql/graphql";
import { serverState } from "../serverState";

export default function NewPublisher() {
  const { setAlertMessage } = useContext(alertContext);
  const navigate = useNavigate();
  const [createPublisher] = useCreatePublisherMutation();
  const currentShopifyDomain = serverState.currentShopifyDomain;

  return (
    <>
      <h1>作成</h1>
      <PublisherForm
        initialValues={{
          name: "",
          ownerId: "",
          shopifyDomain: currentShopifyDomain || "",
        }}
        onSubmit={async (values) => {
          try {
            const result = await createPublisher({
              variables: {
                name: values.name,
                rootOwnerId: values.ownerId,
                shopifyDomain: values.shopifyDomain,
              },
              refetchQueries: [refetchPublisherListQuery()],
            });

            const publisherData = result?.data?.createPublisher;
            if (publisherData?.publisher) {
              navigate("/publishers");
            } else if (publisherData?.errors) {
              setAlertMessage(publisherData.errors.join(", "));
            } else {
              setAlertMessage(
                "システムエラーが発生しました。大変お手数ですが管理者までお問い合わせください。",
              );
            }
          } catch (e) {
            console.error(e);
          }
        }}
      />
    </>
  );
}
