import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useRootOwnerListQuery } from "@/graphql/graphql";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const formSchema = z.object({
  name: z
    .string()
    .min(1, {
      message: "必須項目です。",
    })
    .max(255),
  ownerId: z
    .string()
    .min(1, {
      message: "必須項目です。",
    })
    .max(255),
  shopifyDomain: z
    .string()
    .min(1, {
      message: "必須項目です。",
    })
    .max(255)
    .regex(
      /^[a-z0-9][a-z0-9\-]*\.myshopify\.com$/,
      "Shopifyストアドメインは「xxxxxx.myshopify.com」の形式で入力してください。",
    ),
});

export type PublisherFormProps = {
  initialValues: {
    name: string;
    ownerId: string;
    shopifyDomain: string;
  };
  onSubmit: (values: z.infer<typeof formSchema>) => Promise<void>;
  disableRelation?: boolean;
};

export function PublisherForm({
  initialValues,
  onSubmit,
  disableRelation,
}: PublisherFormProps) {
  const owners = useRootOwnerListQuery();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>出版社名</FormLabel>
              <FormControl>
                <Input placeholder="◯◯出版" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="ownerId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>ROOTコンテンツオーナー</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={initialValues.ownerId}
                  disabled={disableRelation}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="選択してください" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {owners.data?.rootOwners.map((owner) => {
                      return (
                        <SelectItem key={owner.id} value={owner.id}>
                          {owner.name}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="shopifyDomain"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Shopifyストアドメイン</FormLabel>
              <FormControl>
                <Input
                  placeholder="xxxxxx.myshopify.com"
                  {...field}
                  disabled={
                    // NOTE: 出版社編集時、Shopifyストアドメインが設定されている場合は編集不可
                    disableRelation || initialValues.shopifyDomain !== ""
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">送信</Button>
      </form>
    </Form>
  );
}
