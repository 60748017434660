import { Button } from "@/components/ui/button";

export default function SignIn() {
  const getCsrfToken = () => {
    const csrfToken = document.querySelector<HTMLMetaElement>(
      'meta[name="csrf-token"]',
    )?.content;

    return csrfToken;
  };

  return (
    <form action="/users/auth/flb_root" method="POST" className="text-center">
      <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
      <Button
        className="btn btn-primary btn-lg btn-wide font-weight-bold mt-3"
        disabled={false} // TODO: あとで実装
      >
        FLB ROOT ログイン
      </Button>
    </form>
  );
}
