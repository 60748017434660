import { createContext, useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import {
  BrowserRouter,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { Alert } from "@/components/Alert";
import EditPublisher from "@/pages/EditPublisher";
import Home from "@/pages/Home";
import NewPublisher from "@/pages/NewPublisher";
import PublisherList from "@/pages/PublisherList";
import SignIn from "@/pages/SignIn";
import { apolloClient } from "../apollo";
import { serverState } from "../serverState";

export const alertContext = createContext(
  {} as {
    alertMessage: string;
    setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  },
);

export default function App() {
  const pages = import.meta.glob("../pages/**/!(*.test.[jt]sx)*.([jt]sx)", {
    eager: true,
  });

  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </ApolloProvider>
  );
}

function AppContent() {
  const RequireAuthRoute = () => {
    if (serverState.isLoggedIn) {
      return <Outlet />;
    }

    return <Navigate to="/sign_in" replace />;
  };

  const [alertMessage, setAlertMessage] = useState("");
  const location = useLocation();
  useEffect(() => {
    setAlertMessage("");
  }, [location]);

  return (
    <>
      <header className="container mb-5">
        <h1 className="text-xl">
          <Link to="/">HONDANA EBOOK</Link>
        </h1>
        {<Alert alertMessage={alertMessage} />}
      </header>
      <alertContext.Provider value={{ alertMessage, setAlertMessage }}>
        <section className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sign_in" element={<SignIn />} />
            <Route path="/shopify_app/home" element={<Home />} />
            <Route element={<RequireAuthRoute />}>
              <Route path="/publishers" element={<PublisherList />} />
              <Route path="/publishers/new" element={<NewPublisher />} />
              <Route path="/publishers/:id/edit" element={<EditPublisher />} />
            </Route>
          </Routes>
        </section>
      </alertContext.Provider>
    </>
  );
}
