import { useNavigate, useParams } from "react-router-dom";

import { PublisherForm } from "@/components/PublisherForm";
import {
  usePublisherQuery,
  useUpdatePublisherMutation,
} from "@/graphql/graphql";

export default function EditPublisher() {
  const navigate = useNavigate();
  const params = useParams();
  const publisherId = params.id;
  if (!publisherId) {
    return;
  }

  const publisherQuery = usePublisherQuery({
    variables: {
      id: parseInt(publisherId),
    },
  });

  const [updatePublisher] = useUpdatePublisherMutation();

  if (publisherQuery.loading) return <p>Loading...</p>;
  if (!publisherQuery.data?.publisher) {
    return;
  }

  return (
    <>
      <h1>編集</h1>
      <PublisherForm
        initialValues={{
          name: publisherQuery.data.publisher.name,
          ownerId: publisherQuery.data.publisher.rootOwnerId,
          shopifyDomain: publisherQuery.data.publisher.shop.shopifyDomain,
        }}
        onSubmit={async (values) => {
          try {
            updatePublisher({
              variables: {
                id: parseInt(publisherId),
                name: values.name,
              },
            });
            navigate("/publishers");
          } catch (e) {
            console.error(e);
          }
        }}
        disableRelation
      />
    </>
  );
}
