import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

function getCsrfToken() {
  return document
    ?.querySelector('meta[name="csrf-token"]')
    ?.getAttribute("content");
}

const httpLink = new HttpLink({ uri: "/graphql" });
const withCsrfTokenLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "X-CSRF-Token": getCsrfToken(),
    },
  }));
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([withCsrfTokenLink, httpLink]),
  cache: new InMemoryCache(),
});
