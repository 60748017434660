import { Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { usePublisherListQuery } from "@/graphql/graphql";

export default function PublisherList() {
  const { data, loading, error } = usePublisherListQuery();

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <h1>出版社一覧</h1>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>ID</TableHead>
            <TableHead>出版社名</TableHead>
            <TableHead>ROOTコンテンツオーナーID</TableHead>
            <TableHead>Shopifyストアドメイン</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.publishers.map((publisher) => (
            <TableRow key={publisher.id}>
              <TableCell>{publisher.id}</TableCell>
              <TableCell>{publisher.name}</TableCell>
              <TableCell>{publisher.rootOwnerId}</TableCell>
              <TableCell>
                <Link to={`https://${publisher.shopifyDomain}`}>
                  {publisher.shopifyDomain}
                </Link>
              </TableCell>
              <TableCell>
                <Link to={`/publishers/${publisher.id}/edit`}>編集</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
